<template>
  <div>
    <div>
      <div class="d-flex justify-space-between">
        <h1 class="heading primary--text">Water Nitrates And Your Health</h1>
        <v-btn icon>
          <close-window></close-window>
        </v-btn>
      </div>
      <p>
        Explore the association between Nitrates in drinking water and your risk
        for cancer and disease.
      </p>
    </div>
    <div>
      <nitrate-chart v-model="data" @clickBar="handleClick"></nitrate-chart>
      <p class="text-center mt-2 primary--text">
        Click on any bar above to learn more about the health risks of that
        level of nitrate in your water.
      </p>
    </div>
    <div class="detail">
      <nitrate-detail :explore="true" v-model="clicked" :showHideDefault="true">
        <template v-slot:testResultPrefix> What-If My Water </template>
        <template v-slot:close>
          <span></span>
        </template>
      </nitrate-detail>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    NitrateChart: () => import('@/components/Water/BaseChart'),
    NitrateDetail: () => import('@/components/Water/NitrateDetail'),
    CloseWindow: () => import('../components/Core/CloseWindow.vue')
  },
  data: () => ({
    data: [
      { nitrate: 0.5, testDate: '' },
      { nitrate: 1, testDate: '' },
      { testDate: '', nitrate: 1.5 },
      { testDate: '', nitrate: 2 },
      { nitrate: 2.5, testDate: '' },
      { testDate: '', nitrate: 3 },
      { testDate: '', nitrate: 4 },
      { nitrate: 5, testDate: '' },
      { testDate: '', nitrate: 6 },
      { testDate: '', nitrate: 7 },
      { testDate: '', nitrate: 8 },
      { nitrate: 9, testDate: '' },
      { testDate: '', nitrate: 10 }
    ],
    clicked: { nitrate: 10, testDate: '' }
  }),
  methods: {
    handleClick(data) {
      if (data) {
        this.clicked = data
        this.$vuetify.goTo('.detail')
      }
    }
  }
}
</script>
<style scoped>
.heading {
  font-size: 1.6rem;
  font-weight: 500;
}
@media (max-width: 600px) {
  .heading {
    font-size: 1.3rem;
  }
}
</style>
